<template>
  <div>
    <HomePageTop ref="head"/>
    <div element-loading-text=""
         element-loading-background="rgba(0, 0, 0, 0.3)"
         class="orderPage"
         v-loading.fullscreen.lock="fullscreenLoading">
      <div class="main zhu base-w">
        <!-- 头部 -->
        <div class="head fontWeightBold color333" style="border-bottom: none;">
          <span class="flexAndCenter">{{ $fanyi("注文") }}NO：
            {{ $route.query.order_sn }} {{ $fanyi("问题商品明细") }}</span>
        </div>
        <el-tabs v-model="form.status" type="card" @tab-click="getData">
          <el-tab-pane :label="secondText" name="待回复"></el-tab-pane><!--待回复-->
          <el-tab-pane :label="firstsText" name="待后续处理"></el-tab-pane><!--待后续处理-->
          <el-tab-pane :label="secondsText" name="已完成"></el-tab-pane><!--已处理-->
        </el-tabs>
        <!-- 订单列表 -->
        <div v-for="(cItem,cIndex) in newList" :key="cIndex">
          <div class="flexAndCenter orderScreenContainer">
            <div class="fontSize12 fontWeightBold" style="margin:20px 0">{{ $fanyi("注文") }}NO：{{ cItem.order_sn }} {{
                $fanyi("问题商品明细")
              }}
            </div>
          </div>
          <!-- 订单列表 -->
          <div class="issueOrderDetailTable">
            <ul class="flexAndCenter issueOrderDetailTableHeader">
              <li class="inputBox">{{ $fanyi("选择") }}</li>
              <li class="inputBox">{{ $fanyi("番号") }}</li>
              <li class="mirror">{{ $fanyi("写真") }}</li>
              <li class="goods">{{ $fanyi("商品属性") }}</li>
              <li :class="form.status !== '已完成' ?'dataAtTheTimeOfEntry':'resultOfHandling'">
                {{ form.status !== '已完成' ? $fanyi("入金时数据") : '変動内容' }}
              </li>
              <li class="issueNumber">{{ $fanyi("问题数") }}</li>
              <li class="issueDetail">{{ $fanyi("问题详细") }}</li>
              <li class="issueMirror">{{ $fanyi("问题写真") }}</li>
              <li class="operation">{{ form.status !== '待回复' ? $fanyi("处理意见") : $fanyi("操作") }}</li>
            </ul>
            <div class="goodsBox">
              <ul v-for="(item,itemIndex) in cItem.list" :key="item.id">
                <div class="flexAndCenter goodsConUl">
                  <li class="inputBox flexAndCenter" style="justify-content: center">
                    <!--                    選択-->
                    <input type="checkbox" style="margin-left: 0" v-model="item.checked"/>
                  </li>
                  <!--                    番号-->
                  <li class="inputBox">{{ item.sorting }}</li>
                  <!--                    写真-->
                  <li class="mirror" style="flex-direction: column">
                    <el-popover placement="right" trigger="hover">
                      <img :src="item.pic"
                           style="width: 300px; height: 300px"
                      />
                      <img :src="item.pic"
                           slot="reference"
                           @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                           style="width: 60px;height: 60px;cursor: pointer"
                      />
                    </el-popover>
                    <el-popover placement="bottom" trigger="hover">
                      <div>{{ $fanyi("手续费") }}：{{
                          $fun.returnServiceRate(item.service_rate)
                        }}%
                      </div>
                      <div slot="reference">{{ item.from_platform }}</div>
                    </el-popover>
                  </li>
                  <!--                    商品詳細-->
                  <li class="goods">
                    <el-popover trigger="hover">
                      <div class="showDetailCon u-line">
                        <p v-for="(bitem, bindex) in item.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value"
                           class="goodsDetailOpt ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                      <div class="showDetailCon u-line" slot="reference">
                        <div class="flex" style="justify-content: center;flex-direction: column">
                          <p v-for="(bitem, bindex) in item.detail"
                             :key="bitem.key + bindex"
                             :title="bitem.key + ':' + bitem.value"
                             class="goodsDetailOpt ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                      </div>
                    </el-popover>
                  </li>
                  <!--                    入金时数据-->
                  <li :class="form.status !== '已完成' ?'dataAtTheTimeOfEntry':'resultOfHandling'">
                    <!--                    数量：123456 →   -->
                    <div class="newRemarkData" v-if="form.status !== '已完成'">
                      <div>{{ $fanyi("数量") }}：{{ item.offer_num_bak }}</div>
                      <div>{{ $fanyi("单价") }}：{{ item.offer_price_bak }}</div>
                      <div>{{ $fanyi("运费") }}：{{ item.offer_freight_bak }}</div>
                      <div @click="item.showStatus=!item.showStatus">{{ $fanyi("详细") }}</div>
                    </div>
                    <div class="newRemarkData" v-else>
                      <div class="flexAndCenter">{{ $fanyi("数量") }}：{{ item.offer_num_bak }}
                        <span v-if="item.offer_num_bak!=item.now_num"> →<span
                            :style="item.offer_num_bak>item.now_num?'color:#50C136':'color:#B4272B'">{{
                            item.now_num
                          }}</span></span>
                      </div>
                      <div class="flexAndCenter">{{ $fanyi("单价") }}：{{ item.offer_price_bak }}
                        <span v-if="item.offer_price_bak!=item.now_price"> →<span
                            :style="item.offer_price_bak>item.now_price?'color:#50C136':'color:#B4272B'">{{
                            item.now_price
                          }}</span></span>
                      </div>
                      <div class="flexAndCenter">{{ $fanyi("运费") }}：{{ item.offer_freight_bak }}
                        <span v-if="item.offer_freight_bak!=item.now_freight" class="flex">
                          →<span :style="item.offer_freight_bak>item.now_freight?'color:#50C136':'color:#B4272B'">{{
                            item.now_freight
                          }}</span></span>
                      </div>
                      <div class="detailsText" v-if="item.priceUpdateStatus===true"
                           @click="item.showStatus=!item.showStatus">{{ $fanyi("详细") }}
                      </div>
                    </div>
                  </li>
                  <!--                  问题数-->
                  <li class="issueNumber backgroundFAF2F2">{{ item.problem_num }}</li>
                  <!--                  问题详细-->
                  <li class="issueDetail backgroundFAF2F2">
                    <el-popover placement="top" trigger="hover" v-if="item.description_translate!==''">
                      <div>{{ item.description_translate }}</div>
                      <el-input
                          resize="none"
                          v-model="item.description_translate"
                          disabled
                          slot="reference"
                          type="textarea">
                      </el-input>
                    </el-popover>
                  </li>
                  <!--                  问题写真-->
                  <li class="issueMirror flexAndCenter backgroundFAF2F2">
                    <div style="flex-wrap: wrap;margin-top:6px;margin-left:8px" class="flexAndCenter">
                      <div v-for="(picItem,picIndex) in item.problem_goods_image" :key="picIndex">
                        <el-popover placement="right" trigger="hover">
                          <img :src="picItem"
                               style="width: 300px; height: 300px"
                          />
                          <img slot="reference" :src="picItem"
                               style="width:50px;height:50px;margin-left: 8px;margin-bottom:8px">
                        </el-popover>
                      </div>
                    </div>
                  </li>
                  <!--                  处理意见-->
                  <li class="operation">
                    <el-popover placement="top" trigger="hover" v-if="form.status!=='待回复'">
                      <div>{{ item.client_deal }}</div>
                      <el-input
                          resize="none"
                          disabled
                          slot="reference"
                          v-if="item.client_deal!==''"
                          v-model="item.client_deal"
                          @blur="updateClientDeal(item)"
                          type="textarea">
                      </el-input>
                    </el-popover>
                    <div v-else class="newBtn" @click="showNewHandlingSuggestionDialogVisible(item)">{{
                        $fanyi("处理")
                      }}
                    </div>
                  </li>
                </div>
                <div v-show="item.showStatus===true">
                  <div class="ordersListHeader">
                    <div class="goodsNo">{{ $fanyi("番号") }}</div>
                    <div class="goodsImage">{{ $fanyi("图片") }}</div>
                    <div :class="form.status !== '已完成' ? 'newGoodsAttribute' : 'goodsAttribute'">
                      {{ $fanyi("商品属性") }}
                    </div>
                    <div class="newDataAtTheTimeOfEntry" style="width: 150px">{{ $fanyi("入金时数据") }}</div>
                    <div class="newDataAtTheTimeOfEntry" v-show="form.status==='已完成'">現在</div>
                    <div class="option" v-show="form.status==='已完成'">入金时option</div>
                    <div class="option" v-show="form.status==='已完成'">当前option</div>
                    <div class="priceAdjustment" v-show="form.status==='已完成'">{{ $fanyi("金额调整") }}</div>
                    <div v-show="form.status!=='已完成'" class="amountOption">オプション</div>
                    <div v-show="form.status!=='已完成'" class="tag">{{ $fanyi("标签") }}</div>
                    <div v-show="form.status!=='已完成'" class="goodsRemark">{{ $fanyi("备注") }}</div>
                  </div>
                  <div>
                    <div class="ordersListFooter" v-for="(goodsItem,goodsIndex) in item.goodsDetail" :key="goodsIndex">
                      <div class="goodsNo">{{ goodsIndex + 1 }}</div>
                      <div class="goodsImage">
                        <el-popover placement="right" trigger="hover">
                          <el-image :src="goodsItem.pic"
                                    style="width: 300px; height: 300px"
                          />
                          <el-image :src="goodsItem.pic"
                                    slot="reference"
                                    @click="$fun.toCommodityDetails(goodsItem.goods_id,goodsItem.from_platform)"
                                    style="width: 60px;height: 60px"
                          />
                        </el-popover>
                      </div>
                      <div :class="form.status !== '已完成' ? 'newGoodsAttribute' : 'goodsAttribute'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.detail!=null">
                          <div>
                            <div v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                          <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                            <div class="detailListContainer">
                              <div class="u-line" v-for="(detailItem,detailIndex) in goodsItem.detail"
                                   :key="detailIndex">
                                {{ detailItem.key }}：{{ detailItem.value }}
                              </div>
                            </div>
                            <div v-if="goodsItem.detail.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="newDataAtTheTimeOfEntry" style="width: 150px">
                        <div class="fontSize14">
                          <div>{{ $fanyi('数量') }}：{{ goodsItem.offer_num_bak }}</div>
                          <div>{{ $fanyi('单价') }}：{{ goodsItem.offer_price_bak }}</div>
                          <div>{{ $fanyi('运费') }}：{{ goodsItem.offer_freight_bak }}</div>
                        </div>
                      </div>
                      <div class="newDataAtTheTimeOfEntry" v-show="form.status==='已完成'">
                        <div class="fontSize14">
                          <div>{{ $fanyi('数量') }}：{{ goodsItem.now_num }}</div>
                          <div>{{ $fanyi('单价') }}：{{ goodsItem.now_price }}</div>
                          <div>{{ $fanyi('运费') }}：{{ goodsItem.now_freight }}</div>
                        </div>
                      </div>
                      <div class="option" v-show="form.status==='已完成'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="option" v-show="form.status==='已完成'">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="priceAdjustment" v-show="form.status==='已完成'">
                        <div>{{ goodsItem.client_remark }}</div>
                      </div>
                      <div v-show="form.status!=='已完成'" class="amountOption">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                          <div>
                            <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                            <div class="optionListContainer">
                              <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option"
                                   :key="optionIndex">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length>3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div v-show="form.status!=='已完成'" class="tag">
                        <el-popover placement="top" trigger="hover" v-if="goodsItem.order_detail_tag!=undefined">
                          <div>
                            <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                              <div>{{ $fanyi('类别') }}：{{ tagItem.type_translate }}</div>
                              <div>{{ $fanyi('标签号') }}：{{ tagItem.no }}</div>
                              <div>{{ $fanyi('商品码') }}：{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div slot="reference">
                            <div class="goodsTagDetailsContainer cursorPointer">
                              <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                                <div class="u-line">{{ $fanyi('类别') }}：{{ tagItem.type_translate }}</div>
                                <div class="u-line">{{ $fanyi('标签号') }}：{{ tagItem.no }}</div>
                                <div class="u-line">{{ $fanyi('商品码') }}：{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                            <div v-if="goodsItem.order_detail_tag.length>1">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div v-show="form.status!=='已完成'" class="goodsRemark">{{ goodsItem.offer_client_remark }}</div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="operatingOptions" style="align-items: center" v-if="form.status==='待回复'">
        <div class="flexAndCenter">
          <div class="choies flexAndCenter">
            <input type="checkbox" v-model="checked" @change="allChoice"/>
            <button class="qx" @click="checked = !checked;allChoice();">
              {{ $fanyi("全选") }}
            </button>
          </div>
          <div class="flexAndCenter" style="margin-left:60px"><span class="color333">{{ $fanyi("已选择") }}</span> <span
              class="fontWeightBold" style="color:#B4272B;display: inline-block;margin:0 8px">{{ goodsTotal }}</span>
            <span class="color333">{{ $fanyi("条") }}</span></div>
        </div>
        <el-button size="mini" type="primary" style="margin-right:26px"
                   v-show="form.status==='待回复'&&newList.length>0" @click="showHandlingSuggestionDialogVisible">
          {{ $fanyi("问题产品批量回复") }}
        </el-button>
      </div>
    </div>
    <!--      处理意见弹窗-->
    <div class="dialogContainer">
      <el-dialog
          width="415px"
          :title="$fanyi('处理意见')"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="handlingSuggestionDialogVisible">
        <div style="margin-top: 26px" class="radioListContainer">
          <el-radio-group v-model="radioValue" @change="opinionValue=''">
            <el-radio :label="1">{{ $fanyi("接受") }}</el-radio>
            <el-radio :label="2">{{ $fanyi("不良品换货") }}</el-radio>
            <el-radio :label="3">{{ $fanyi("退货/取消购买") }}</el-radio>
            <el-radio :label="4">{{ $fanyi("废弃") }}</el-radio>
            <el-radio :label="5">{{ $fanyi("其他") }}</el-radio>
          </el-radio-group>
        </div>
        <el-input v-show="radioValue===5" v-model="opinionValue" resize="none" type="textarea"
                  style="margin-top: -10px"
                  :placeholder="$fanyi('请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。')"/>
        <div class="btnList flexAndCenter">
          <div @click="handlingSuggestionDialogVisible=false">{{ $fanyi("取消") }}</div>
          <div @click="submitOrderHandlingSuggestion">{{ $fanyi("保存") }}</div>
        </div>
      </el-dialog>
    </div>
    <FootVue style="margin-top: 80px"></FootVue>
  </div>
</template>

<script>
import Dialog from "../../../../../components/public/Dialog";
import FootVue from "../../../../../components/foot/Foot.vue";
import HomePageTop from "../../../../../components/head/HomePageTop.vue";

export default {
  data() {
    return {
      checked: false,
      form: {
        status: '待回复',
        order_sn: this.$route.query.order_sn
      },
      radioValue: 1,
      opinionValue: '',
      handlingSuggestionDialogVisible: false,
      goodsTotal: 0,
      newList: [],
      fromPlatformList: ['1688', 'taobao', 'tmall'],
      fullscreenLoading: false,
      secondText: this.$fanyi('待后续处理') + '（0）',
      firstsText: this.$fanyi('待回复') + '（0）',
      secondsText: this.$fanyi('已处理') + '（0）',
      orderServiceRateList: [],
    };
  },
  components: {
    HomePageTop,
    Dialog,
    FootVue,
  },
  created() {
    this.$api.orderGetServiceRate({from_platform: this.fromPlatformList}).then((res) => {
      this.orderServiceRateList = res.data;
    });
    this.getProblemGoodsOrderNum();
    this.getData();
  },
  methods: {
    getData() {
      this.$api.problemGoodsOrderList(this.form).then((res) => {
        if (res.code != 0) return;
        res.data.data.forEach((item) => {
          item.checked = false
          item.list.forEach((items) => {
            items.optionCheckedNum = 0;
            items.checked = false;
            items.service_rate = this.$fun.getServiceRate(this.orderServiceRateList, items.from_platform);
            items.showStatus = false;
            items.priceUpdateStatus = (items.offer_num_bak != items.now_num) || (items.offer_price_bak != items.now_price) || (items.offer_freight_bak != items.now_freight);
            items.goodsDetail = [];
            items.goodsDetail.push(items);
          })
        })
        this.total = res.data.total;
        this.newList = res.data.data;
      });
    },
    showNewHandlingSuggestionDialogVisible(val) {
      this.defectiveProductIdList = val.problem_goods_id;
      this.handlingSuggestionDialogVisible = true;
    },
    //是否展示处理意见弹窗弹窗
    showHandlingSuggestionDialogVisible() {
      this.defectiveProductIdList = '';
      this.newList.forEach((item) => {
        item.list.forEach((items) => {
          if (items.checked === true) {
            this.defectiveProductIdList += items.problem_goods_id + ','
          }
        })
      })
      this.defectiveProductIdList = this.defectiveProductIdList.substr(0, this.defectiveProductIdList.length - 1);
      if (this.defectiveProductIdList === '') {
        this.$message.error(this.$fanyi("请至少选择一个问题产品"))
        return
      }
      this.handlingSuggestionDialogVisible = true;
    },
    //获取各个状态下的数量
    getProblemGoodsOrderNum() {
      this.$api.problemGoodsOrderNum({order_sn: this.$route.query.order_sn}).then((res) => {
        res.data.forEach((item) => {
          switch (item.name) {
            case '待回复':
              this.secondText = this.$fanyi('待后续处理') + `（${item.count}）`;
              break;
            case '待后续处理':
              this.firstsText = this.$fanyi('待回复') + `（${item.count}）`;
              break;
            case '已完成':
              this.secondsText = this.$fanyi('已处理') + `（${item.count}）`;
              break;
          }
        })
      });
    },
    //更新问题产品处理意见
    updateClientDeal(item) {
      if (item.client_deal !== '') {
        this.$api.problemGoodsClientDeal({
          problem_goods_id: item.problem_goods_id,
          client_deal: item.client_deal
        }).then((res) => {
          if (res.code != 0) return;
          this.$message.success(this.$fanyi(res.msg));
          this.getData();
          this.getProblemGoodsOrderNum();
        });
      }
    },
    //单选处理
    radio() {
      this.goodsTotal = 0;
      let orderCount = 0;//订单选中数量
      this.newList.forEach((item) => {
        item.list.forEach((items, itemsIndex) => {
          let count = 0;//商品选中数量
          //如果商品被选中了商品选中数量增加
          //商品选中时总商品选中数量增加，否则减少
          if (items.checked === true) {
            this.goodsTotal++
          }
          if (items.checked) {
            count++
          }
          //如果到最后一条数据时商品选中数量等于订单数组的数据，就选中店铺全选
          item.checked = count === item.list.length && itemsIndex === item.list.length - 1;
          //如果到最后一条数据时订单选中数量等于订单数组的数据，就选中全选
          if (item.checked === true) {
            orderCount++
          }
          this.checked = orderCount === this.newList.length;
        })
      })
    },
    allChoice() {
      this.goodsTotal = 0;
      this.newList.forEach((item) => {
        item.checked = this.checked
        item.list.forEach((items) => {
          items.checked = this.checked
          if (items.checked) {
            this.goodsTotal++;
          }
        })
      })
    },
    //提交问题产品处理意见
    submitOrderHandlingSuggestion() {
      //处理意见是其他并且备注没填写
      if (this.radioValue === 5 && this.opinionValue === '') {
        this.$message.error(this.$fanyi("请输入处理意见"))
        return
      }
      //处理意见不是其他则添加快捷处理文字加客户选择的处理方式
      if (this.radioValue !== 5) {
        switch (this.radioValue) {
          case 1:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('接受');
            break;
          case 2:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('不良品换货');
            break;
          case 3:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('退货/取消购买');
            break;
          case 4:
            this.opinionValue = this.$fanyi('快捷处理') + this.$fanyi('废弃');
            break;
        }
      }
      this.$api.problemGoodsClientDeal({
        problem_goods_id: this.defectiveProductIdList,
        client_deal: this.opinionValue
      }).then((res) => {
        if (res.code != 0) return;
        this.handlingSuggestionDialogVisible = false;
        this.$message.success(this.$fanyi(res.msg))
        this.getData();
        this.getProblemGoodsOrderNum();
      });
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../../css/mixin.scss";

.main {
  width: 1400px;
  margin: 30px auto 0;
  background: #ffffff;
  padding: 26px;
  border-radius: 10px 10px 0 0;

  .head {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > h1 {
      font-size: 18px;

      font-weight: bold;
      color: #000000;
      line-height: 24px;
    }

    .el-button {
      border-radius: 4px;
    }
  }

  .sn {
    margin-bottom: 20px;

    &,
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }
  }
}

.backgroundFAF2F2 {
  background: #FAF2F2 !important;
}

.dialogContainer {
  /deep/ .el-dialog {
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ .el-dialog__header {
    padding: 0;
    display: flex;
    justify-content: center;

    .el-dialog__title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      padding-bottom: 7px;
      border-bottom: 4px solid #B4272B;
      text-align: center;
    }

    .el-dialog__headerbtn {
      top: 30px;
      font-size: 19px;

      .el-dialog__close {
        color: #B4272b;
      }
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  .radioListContainer {
    /deep/ .el-radio {
      margin-right: 0;
      width: 105px;
      margin-bottom: 25px;
    }
  }

  /deep/ .el-textarea__inner {
    height: 80px;
    margin-bottom: 30px;
  }

  .btnList {
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 14px;

      border: 1px solid #B4272B;
    }

    div:first-child {
      background: #fff;
      color: #B4272B;
      border: 1px solid #B4272B;
      margin-right: 30px;
    }
  }
}

.issueOrderDetailTableHeader {
  background: #F0F0F0;
  height: 56px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;

  li {
    line-height: 56px;
    text-align: center;
    font-size: 14px;
    color: #333;
  }
}

.operatingOptions {
  width: 1400px;
  height: 70px;
  position: sticky;
  margin: 20px auto;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0px;
  border: 1px solid #e8e8e8;
  // height: 106px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0 0;
  display: flex;

  padding: 10px 0;
  justify-content: space-between;

  .huiLv {
    font-size: 10px;
    color: #888888;
    line-height: 19px;
  }

  /deep/ .el-button {
    height: 34px;
  }

  .deleteText {
    cursor: pointer;
    color: #B4272B;
    font-size: 16px;
    margin: 0 26px;
  }

  .AdditionalBtn {
    width: 80px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 14px;
    margin-right: 52px;
  }

  .goodsNum {
    color: #333333;

    div {
      font-weight: bold;
      color: #B4272B;
      margin: 0 8px;
    }

    margin-right: 51px;
  }

  .shiYongYouHuiQuan {
    .tiShi {
      font-size: 14px;
      line-height: 19px;
      color: #888888;
    }

    .userYouHuiQuan {
      margin-left: 10px;
      background-color: transparent;
    }
  }

  .newPrice {
    font-weight: bold;
    color: #B4272B;
    line-height: 28px;
  }

  .choies {
    /deep/ input[type="checkbox"]:checked {
      background: #4A91E9;
    }

    .caoZuoCaiDan {
      font-size: 14px;
      color: $homePageSubjectColor;
      line-height: 19px;

      cursor: pointer;
    }

    .delBtn {
      margin-right: 20px;
    }

    // 加入商品库按钮
    .addLibraryOfGoods {
      height: 19px;
      font-size: 14px;
      margin-left: 10px;

      color: #47bd9b !important;
      line-height: 19px;
      letter-spacing: unset;
    }
  }

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      color: #888888;
      font-size: 14px;
      margin-right: 40px;

      input {
        margin-left: 26px;
        margin-right: 6px;
      }

      > button {
        font-size: 14px;

        color: #888888;
        line-height: 19px;

        background-color: transparent;

        &:first-child,
        &:nth-child(2) {
          color: #888888;
        }

        &.addgoods {
          color: #3a4de4;
          margin: 0 20px 0 40px;
        }

        &:nth-child(5) {
          color: #ffa724;
        }
      }
    }

    &.shop {
      flex: 1;

      padding-right: 40px;

      > p {
        flex: 1;
        font-size: 14px;
        color: #000000;
        line-height: 19px;

        &.shopGr {
          flex: 0 0 207px;

          span {
            white-space: nowrap;
          }
        }

        &.prices {
          text-align: right;
          display: flex;
          flex-direction: column;

          span {
            &.priceEURBox {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            line-height: 20px;
            justify-content: flex-end;
            // margin
            .renMingBi {
              margin-left: -3px;
              margin-right: 5px;
            }

            .priceBox {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
        }

        > span {
          line-height: 20px;
          margin: auto 0;
          display: flex;
          align-items: center;
        }

        .tiJi {
          margin-left: 40px;
        }
      }
    }

    &.buttonGroup {
      margin-left: -30px;
      padding-right: 10px;

      > button {
        width: 120px;
        height: 34px;
        border-radius: 8px;
        border: 1px solid #B4272B;
        font-size: 14px;
        font-weight: 400;
        color: #B4272B;
        background: white;
        line-height: 34px;
        padding: 0 20px;
        margin: 10px 10px;
        font-family: "Open Sans", Sans-serif;

        &.payBtn {
          color: #ffffff;
          background: #47bd9b;
        }

        &.chongXinTiChu {
          color: #ffffff;
          background: #47bd9b;
        }

        &:nth-child(2) {
          color: #ffffff;
          background: #B4272B;
        }
      }
    }
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}

.optionHiddenContainer {
  height: 60px;
  overflow: hidden;
}

.inputBox {
  width: 50px;
}

.newGoods {
  width: 130px;
}

.mirror {
  width: 88px;
}

.goods {
  width: 160px;

  .optionHiddenContainer {
    height: 53px !important;
    overflow: hidden;
  }
}

.dataAtTheTimeOfEntry {
  width: 115px;

  .newRemarkData {
    div {
      width: 100%;
      text-align: left;
    }

    div:last-child {
      text-decoration: underline;
      font-size: 14px;
      color: #B4272B;
      cursor: pointer;
    }
  }
}

.remark {
  width: 120px;

  /deep/ .el-textarea__inner {
    width: 110px;
    height: 100px;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
  }
}

.issueMirror {
  width: 140px;

  div {
    flex-wrap: wrap;
    margin-top: 6px;
    margin-left: 8px;

    img {
      width: 50px;
      height: 50px;
      margin-left: 8px;
      margin-bottom: 8px
    }
  }
}

.issueNumber {
  width: 60px;
}

.issueDetail {
  width: 248px;

  /deep/ .el-textarea__inner {
    width: 230px;
    height: 100px;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    color: #000;
  }
}

.shopDetailContainer {
  height: 60px;
  background-color: #fff;
}

.goodsConUl {
  height: 120px;
  background: #F9F9F9;

  li {
    text-align: center;
    font-size: 14px;
    color: #333;
    background: #F9F9F9;
    height: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .showDetailCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;

    p {
      width: 120px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.ordersListHeader {
  display: flex;
  background: #D7D7D7;

  div {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    height: 42px;
    line-height: 42px;
  }
}

.goodsNo {
  width: 60px;
}

.goodsImage {
  width: 88px;
}

.goodsAttribute {
  width: 180px;
}

.newGoodsAttribute {
  width: 200px;

  .goodsAttributeDetailsContainer {
    .detailListContainer {
      width: 139px;
      height: 56px;
      overflow: hidden;
    }

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 139px;
    }
  }
}

.amountOption {
  width: 200px;

  .goodsAttributeDetailsContainer {
    .optionListContainer {
      width: 152px;
      height: 56px;
      overflow: hidden;
    }

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 152px;
    }
  }
}

.tag {
  width: 220px;

  .goodsAttributeDetailsContainer {
    .optionListContainer {
      width: 172px;
      height: 56px;
      overflow: hidden;
    }

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 172px;
    }
  }
}

.newDataAtTheTimeOfEntry {
  width: 140px;
}

.option {
  width: 167px;
}

.priceAdjustment {
  width: 160px;
}

.goodsRemark {
  width: 184px;
}

.resultOfHandling {
  width: 180px;

  .newRemarkData {
    width: 100%;

    .flexAndCenter {
      width: 100%;
      text-align: left;
    }

    .detailsText {
      text-decoration: underline;
      font-size: 14px;
      color: #B4272B;
      cursor: pointer;
      text-align: left;
    }
  }
}

.ordersListFooter {
  display: flex;
  background: #FFF8EE;
  height: 120px;

  .ordersNumber, .goodsNo, .goodsImage, .goodsAttribute, .newDataAtTheTimeOfEntry, tag, .quantityShipped, .option, .ordersNumber, .priceAdjustment, .newGoodsAttribute, .amountOption {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.operation {
  width: 186px;

  /deep/ .el-textarea__inner {
    width: 162px;
    height: 100px;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    color: #000;
  }

  .newBtn {
    width: 60px;
    height: 30px;
    background: #B4272B;
    border-radius: 6px;
    line-height: 30px;
    text-align: center;
    color: #FEFEFE;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
